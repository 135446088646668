<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false"
			:close-on-press-escape="false" :before-close="handleClose">
			<div class="now">你当前正在</div>
			<el-steps :active="active" align-center>
				<el-step title="发布需求"></el-step>
				<el-step title="完善需求内容"></el-step>
				<el-step title="接单中标"></el-step>
				<el-step title="支付首款定金"></el-step>
				<el-step title="服务方工作"></el-step>
				<el-step title="验收成果"></el-step>
				<el-step title="支付尾款"></el-step>
				<el-step title="交易完成"></el-step>
			</el-steps>
			<!-- 发布需求 -->
			<div class="state" v-if="active < 2">
				<img src="../../assets/images/serve/gth.png" alt="" />
				正在 <span>发布需求</span>
			</div>
			<div class="state" v-if="active ==2">
				<img src="../../assets/images/serve/gth.png" alt="" />
				已发布成功 <span>等待投标</span>
			</div>
			<div class="state" v-if="active ==3 && payState!=0">
				<img src="../../assets/images/serve/gth.png" alt="" />
				已中标 <span>待支付首款</span>
			</div>
			<div class="state" v-if="active ==3 && payState==0">
				<img src="../../assets/images/serve/gth.png" alt="" />
				已中标,需求方支付完首款<span>工作中</span>
			</div>
			<div class="state" v-if="active ==4">
				<img src="../../assets/images/serve/gth.png" alt="" />
				<span>待验收</span>
			</div>
			<div class="state" v-if="active ==5">
				<img src="../../assets/images/serve/gth.png" alt="" />
				验收工作成功<span>待付尾款</span>
			</div>
			<div class="state" v-if="active ==6">
				<img src="../../assets/images/serve/gth.png" alt="" />
				<span>支付尾款</span>
			</div>
			<div class="state" v-if="active ==7 ||active == 8">
				<img src="../../assets/images/serve/gth.png" alt="" />
				<span>交易完成</span>
			</div>
			<div v-if="active == 0 && !represent">
				<!-- 发布需求 -->
				<el-form style="margin-top: 40px" label-position="left" label-width="100px" :rules="rules"
					:model="form1" @submit.native.prevent>
					<el-form-item label="需求类别" prop="classifyId">
						<div class="code">
							<el-select v-model="form1.classifyId" style="width: 490px" placeholder="请选择需求类别">
								<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="需求名称" prop="name">
						<div class="code">
							<input style="width: 490px" placeholder="少于20个字" v-model="form1.name" />
						</div>
					</el-form-item>
					<el-form-item label="预算金额" prop="sendMoneyBudget">
						<div class="code">
							<input style="width: 287px" type="number" v-model="form1.sendMoneyBudget" />
							<!-- <div style="margin-left:10px">元 </div> -->
							<div style="margin-left: 10px">
								元 <span style="color: #2ea7e0">（可查看数量10）</span>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="联系电话" prop="accountPhone">
						<div class="code">
							<input style="width: 287px" placeholder="" v-model="info.accountPhone" readonly />
						</div>
					</el-form-item>
					<el-form-item label="图形验证码:">
						<div class="code">
							<input style="width: 364px" v-model="smaCode" type="text" />
						</div>
						<!-- <el-input style="width: 364px;height:50px" v-model="form.smaCode"></el-input> -->
						<div class="verification">
							<Code ref="ref_validateCode1" @change="changeCode" />
							<div class="text" @click="changeImg(1)">看不清？<span>换张图</span></div>
						</div>
					</el-form-item>

					<div class="privacy">你的隐私正在保护中...</div>
					<div class="foot-btn">
						<el-button class="orders" @click="compare(1)">确认发布</el-button>
					</div>
				</el-form>
			</div>
			<!-- 完善需求内容 -->
			<!-- -->
			<div  v-if="active == 1 && !represent">
				<div class="orderInformation" v-if="obtain == 0">
					<div class="cell">
						<div>订单号</div>
						<span class="red">待完善需求后生成</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ form1.name }} <span style="color: #2ea7e0">（可查看数量10）</span></span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span class="red">待填写</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ form1.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>待填写</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span>{{ info.accountPhone }} （号码保护中）</span>
					</div>
				</div>
				<div class="orderInformation" v-else>
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span v-if="orderInfo.sendMoneyIntention == 0">待填写</span>
						<span v-if="orderInfo.sendMoneyIntention != 0">￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 需求描述 -->
				<div v-if="obtain == 0">
					<el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="form1" :rules="rules"
						@submit.native.prevent>
						<el-form-item label="需求描述" prop="sendMsgBase">
							<!-- <div class="code"> -->
							<el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea"
								placeholder="500字以内" v-model="form1.sendMsgBase" maxlength="500"></el-input>
							<!-- </div> -->
						</el-form-item>
						<el-form-item label="地区：" prop="sendProvince">
							<div class="code">
								<el-select v-model="form1.sendProvince" placeholder="地区">
									<el-option v-for="item in proList" :key="item.id" :label="item.province"
										:value="item.provinceid"></el-option>
								</el-select>
								<div style="margin: 0 20px">省</div>
								<el-select v-if="form1.province != ''" v-model="form1.sendCity" placeholder="地区">
									<el-option v-for="item in cityList" :key="item.id" :label="item.city"
										:value="item.cityid"></el-option>
								</el-select>
								<div v-if="form1.province != ''" style="margin: 0 20px">市</div>
							</div>
						</el-form-item>
						<el-form-item label="行业" prop="sendIndustry">
							<div class="code">
								<el-select v-model="form1.sendIndustry" placeholder="行业">
									<el-option v-for="item in industryList" :key="item.id" :label="item.title"
										:value="item.id"></el-option>
								</el-select>
							</div>
						</el-form-item>
						<el-form-item label="其他要求">
							<!-- <div class="code"> -->
							<el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea"
								placeholder="120字以内" v-model="form1.sendMsgOther"></el-input>
							<!-- </div> -->
						</el-form-item>
						<el-form-item label="手机验证码">
							<div class="code">
								<input style="width: 360px" v-model="form1.smsCode" type="text" />
								<button @click="getCode" v-show="!countDownShow">发送验证码</button>
								<button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
									{{ countDown }}
								</button>
							</div>
						</el-form-item>
					</el-form>
					<div class="privacy">你的隐私正在保护中...</div>
					<!-- 底部按钮 -->
					<div>
						<div class="foot-btn">
							<el-button class="orders" @click="release">确认完善</el-button>
						</div>
						<!-- <div class="foot-btn">
                        <el-button class="orders wait-btn">确认</el-button>
                        <div class="give-up">放弃接单</div>
                    </div> -->
					</div>
				</div>
				<!-- 支付-->
				<div  v-else>
					<div class="hint"><span style="color: red;">*</span>支付方式</div>
					<div class="payment">
						<el-radio v-model="form2.payType" :label="0" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png"
										alt="" />
									我的钱包
									<span>余额：{{ info.balance }}元</span>
								</div>
							</div>
						</el-radio>
						<div>平台</div>
					</div>
					<div class="payment">
						<el-radio v-model="form2.payType" :label="1" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png"
										alt="" />
									支付宝
								</div>
							</div>
						</el-radio>
					</div>
					<div class="payment">
						<el-radio v-model="form2.payType" :label="2" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png"
										alt="" />
									微信
								</div>
							</div>
						</el-radio>
					</div>
					<div class="hint"><span style="color: red;">*</span>预付金额</div>
					<div class="backJd">
						<div class="moengt">￥ <input type="number" v-model="form2.money" /></div>
						<div style="margin-left: 20px">“预付金额”为“悬赏发布”通道，支付后可吸引更多服务商，删除订单可自动退回账户，也可选择“免费发布”无需任何悬赏付款。</div>
					</div>
					<div v-if="form2.payType == 0">
						<div class="hint"><span style="color: red;">*</span>支付密码</div>
						<div class="paypass">
							<input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num"
								:id="'captcha' + index" @input="inputFinash(index)" @focus="adjust(index)"
								@keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" />
						</div>
					</div>
					<div></div>
					<div class="privacy">你的隐私正在保护中...</div>
					<!-- 底部按钮 -->
					<div v-show="!qrCodeShow">
						<div class="foot-btn">
							<el-button v-show="form2.payType == 0" class="orders" style="background: #409EFF;" @click="intention(1)">悬赏发布</el-button>
							<el-button v-show="form2.payType == 0" class="orders give-up" @click="intention(0)">免费发布</el-button>
							<!-- <div class="orders give-up"  @click="intention(0)">
								免费发布
							</div> -->
							<el-button class="orders" v-show="form2.payType == 1 || form2.payType == 2"
								@click="intention(1)">生成付款二维码</el-button>
						</div>
						<!-- <div class="hui"></div> -->
						<div class="hui">
							点击【付款后】款项在验收成果确认之前将由平台保管，待验收成功后将自动发往服务方。
						</div>
					</div>
					<!-- 二维码显示 -->
					<div class="qr-code" ref="qrCodeUrl" v-show="qrCodeShow"></div>
				</div>
			</div>
			<!-- 接单中标 -->
			<div v-if="active == 2 && !represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span>{{ orderInfo.sendPhone }} （号码保护中）</span>
					</div>
				</div>
				<div class="menus">
					<div class="li">服务方账号</div>
					<div class="li">接单时间</div>
					<div class="li" style="width: 200px">联系方式</div>
					<div class="li" style="width: 210px">我接受</div>
				</div>
				<div class="menus-cell" v-for="item in bidList" :key="item.id">
					<div class="li">
						<el-checkbox v-model="item.status"></el-checkbox>
						<div>{{ item.getUid }}</div>
					</div>
					<div class="li">{{ item.createTime }}</div>
					<div class="li" style="color: #2ea7e0; width: 200px" v-if="!item.openPhone"
						@click="item.openPhone = true">
						点击获取
					</div>
					<div class="li" style="width: 200px" v-else>{{ item.phone }}</div>
					<div class="li" style="width: 210px">{{ item.msg }}</div>
				</div>

				<div class="privacy">你的隐私正在保护中...</div>
				<div>
					<div class="foot-btn">
						<el-button class="orders" :disabled="btnDelet" @click="winBid">中标</el-button>
						<el-button class="disuse" @click="disuse">淘汰</el-button>
						<div class="give-up" @click="appealOpen">申诉</div>
					</div>

					<div class="hui">
						选择中标者后等中标方确认后系统自动跳转至下一步，如中标方三个工作日内未确认则淘汰该中标方重新选择
					</div>
				</div>
			</div>
			<!-- 申请付款  待支付首款  需求方支付完首款-->
			<div v-if="active == 3 && !represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName || "待确认" }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span v-if="payState != 0">待中标服务方确认后显示</span>
						<span v-if="payState == 0">{{ orderInfo.winUserPhone }}</span>
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<!-- 支付首款定金 -->
				<!-- <div class="wenj">
          服务方上传文件
          <a
            :href="`${$util.host}/file/download?fileUrl=${orderInfo.contract}&token=${token}`"
            target="_blank"
          >
            <i style="font-size: 50px; margin-left: 10px" class="el-icon-document"></i>
            <span>下载</span>
          </a>
       <i style="font-size:25px;margin-left:10px" class="el-icon-document" @click="dowmFild"></i> 
        </div> -->
				<div v-if="payState == 0">
					<div class="hint"><span style="color: red;">*</span>支付方式</div>
					<div class="payment">
						<el-radio v-model="form3.payType" :label="0" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png"
										alt="" />
									我的钱包
									<span>余额：{{ info.balance }}元</span>
								</div>
							</div>
						</el-radio>
						<div>平台</div>
					</div>
					<div class="payment">
						<el-radio v-model="form3.payType" :label="1" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png"
										alt="" />
									支付宝
								</div>
							</div>
						</el-radio>
					</div>
					<div class="payment">
						<el-radio v-model="form3.payType" :label="2" class="raido">
							<div class="raido-info">
								<div>
									<img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png"
										alt="" />
									微信
								</div>
							</div>
						</el-radio>
					</div>
					<div class="hint"><span style="color: red;">*</span>预首款定金</div>
					<div class="deposit">
						<div class="price">
							<span>{{ orderInfo.moneyAll.toFixed(2) }}</span>
							<div>（已支付意向金{{ orderInfo.sendMoneyIntention }}元）</div>
						</div>
						<div>
							注：若支付全款后意向金有所剩余，平台将在服务方收款后将剩余金额退回
							<span>【我的钱包】</span>
						</div>
					</div>
					<div class="qr-code" ref="qrCodeUrl" v-show="qrCodeShow"></div>
					<div class="hint" v-if="form3.payType == 0"><span style="color: red;">*</span>支付密码</div>
					<div class="paypass" v-if="form3.payType == 0">
						<input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num"
							:id="'captch' + index" @input="inputFinasho(index)" @focus="adjusto(index)"
							@keydown="inputDirectiono(index)" class="captcha_input_box row-center" maxlength="1" />
					</div>
					<div class="privacy">你的隐私正在保护中...</div>
					<!-- 按钮 -->
					<div class="foot-btn">
						<el-button class="orders" @click="firstModel">付款</el-button>
						<!-- <div class="give-up">不了，直接下一步</div> -->
					</div>
					<div class="hui">
						点击【付款后】款项在验收成果确认之前将由平台保管，待验收成功后将自动发往服务方。
					</div>
					<!-- <div class="foot-content">
            <div class="cell">
              <div>公司名称:</div>
            </div>
            <div class="cell">
              <div>银行账户:</div>
            </div>
            <div class="cell">
              <div>开户行:</div>
            </div>
          </div>
          <div style="color: #999">
            付款后将付款截图发给客服人员核实后可继续操作下一步
          </div>-->
				</div>
				<div class="foot-btn" v-else>
					<el-button class="orders wait-btn">等待中标方确认</el-button>
					<div class="give-up" @click="appealOpen">申诉</div>
				</div>
			</div>
			<!-- 服务方工作 待验收  验收工作成功-->
			<div v-if="active == 4 && !represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName }}
					</div>
					<div class="cell">
						<div>我接受</div>
						{{ orderInfo.winMsgOther }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						{{ orderInfo.winUserPhone }}
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待服务方填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<div class="no-win">
					<img src="../../assets/images/serve/gzz.png" alt="" />
					服务方工作中......
				</div>
				<!-- 底部按钮 -->
				<div>
					<div class="foot-btn">
						<!-- <el-button class="orders" @click="pay">确认验收</el-button>
                        <div class="give-up">申诉</div>
                    </div>
                    <div class="hui">注：点击【确认验收】后若尾款为0元时将自动完成订单</div>
                    <div class="foot-btn"> -->
						<el-button class="orders wait-btn">等待服务方上传验收文件</el-button>
						<div class="give-up" @click="appealOpen">申诉</div>
					</div>
				</div>
			</div>
			<!-- 验收成果 -->
			<div v-if="active == 5 && !represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName }}
					</div>
					<div class="cell">
						<div>我接受</div>
						{{ orderInfo.winMsgOther }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						{{ orderInfo.winUserPhone }}
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待服务方填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<div class="wenj">
					服务方上传文件
					<a :href="`${$util.host}/file/download?fileUrl=${orderInfo.winOverFile}&token=${token}`"
						target="_blank">
						<i style="font-size: 50px; margin-left: 10px" class="el-icon-document"></i>
						<span>下载</span>
					</a>
					<!-- <i style="font-size:25px;margin-left:10px" class="el-icon-document" @click="dowmFild"></i>  -->
				</div>
				<!-- <div class="no-win">
                    <img src="../../assets/images/serve/gzz.png" alt="">
                    服务方工作中......
                </div> -->
				<!-- 底部按钮 -->
				<div>
					<div class="foot-btn">
						<el-button class="orders" @click="acceptance">确认验收</el-button>
						<div class="give-up" @click="appealOpen">申诉</div>
					</div>
					<div class="hui">注：点击【确认验收】后若尾款为0元时将自动完成订单</div>
					<!-- <div class="foot-btn">
                        <el-button class="orders wait-btn">等待服务方上传验收文件</el-button>
                        <div class="give-up">申诉</div>
                    </div> -->
				</div>
			</div>
			<!-- 支付尾款 -->
			<div v-if="active == 6 && !represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName }}
					</div>
					<div class="cell">
						<div>我接受</div>
						{{ orderInfo.winMsgOther }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						{{ orderInfo.winUserPhone }}
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待服务方填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<div class="hint"><span style="color: red;">*</span>支付方式</div>
				<div class="payment">
					<el-radio v-model="form4.payType" :label="0" class="raido">
						<div class="raido-info">
							<div>
								<img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png"
									alt="" />
								我的钱包
								<span>余额：{{ info.balance }}元</span>
							</div>
						</div>
					</el-radio>
					<div>平台</div>
				</div>
				<div class="payment">
					<el-radio v-model="form4.payType" :label="1" class="raido">
						<div class="raido-info">
							<div>
								<img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
								支付宝
							</div>
						</div>
					</el-radio>
				</div>
				<div class="payment">
					<el-radio v-model="form4.payType" :label="2" class="raido">
						<div class="raido-info">
							<div>
								<img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
								微信
							</div>
						</div>
					</el-radio>
				</div>
				<div class="hint">支付尾款</div>
				<div class="deposit">
					<div class="price">
						<span>{{ orderInfo.sendMoneyLess.toFixed(2) }}</span>
						<div>（已支付意向金{{ orderInfo.sendMoneyIntention }}元）</div>
					</div>
					<div>
						注：若支付全款后意向金有所剩余，平台将在服务方收款后将剩余金额退回
						<span>【我的钱包】</span>
					</div>
				</div>
				<div class="qr-code" ref="qrCodeUrl" v-show="qrCodeShow"></div>
				<div class="hint" v-if="form4.payType == 0"><span style="color: red;">*</span>支付密码</div>
				<div class="paypass" v-if="form4.payType == 0">
					<input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num"
						:id="'captchb' + index" @input="inputFinashb(index)" @focus="adjustb(index)"
						@keydown="inputDirectionb(index)" class="captcha_input_box row-center" maxlength="1" />
				</div>
				<div class="privacy">你的隐私正在保护中...</div>
				<!-- 按钮 -->
				<div class="foot-btn">
					<el-button class="orders" @click="tail">付款</el-button>
				</div>
				<div class="hui">
					点击【付款后】款项在验收成果确认之前将由平台保管，待验收成功后将自动发往服务方。
				</div>
				<!-- <div class="foot-content">
          <div class="cell">
            <div>公司名称:</div>
          </div>
          <div class="cell">
            <div>银行账户:</div>
          </div>
          <div class="cell">
            <div>开户行:</div>
          </div>
        </div>
        <div style="color: #999">付款后将付款截图发给客服人员核实后可继续操作下一步</div> -->
			</div>
			<!-- 交易完成 -->
			<div v-if="(active == 7 && !represent) || (active == 8 && !represent)">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.id }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>

						<span>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName }}
					</div>
					<div class="cell">
						<div>我接受</div>
						{{ orderInfo.winMsgOther }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.winUserPhone }}</span>
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待服务方填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<!-- 底部按钮 -->
				<div class="no-win">
					<img src="../../assets/images/serve/qr.png" alt="" />
					交易已完成！
				</div>
			</div>
			<!-- 申述 -->
			<div v-if="represent">
				<div class="orderInformation">
					<div class="cell">
						<div>订单号</div>
						<span>{{ orderInfo.orderId }}</span>
					</div>
					<div class="cell">
						<div>需求类别</div>
						<span>{{ className }}</span>
					</div>
					<div class="cell">
						<div>需求名称</div>
						<span>{{ orderInfo.name }}</span>
					</div>
					<div class="cell">
						<div>需求描述</div>
						<span>{{ orderInfo.sendMsgBase }}</span>
					</div>
					<div class="cell">
						<div>预算金额</div>
						<span>￥{{ orderInfo.sendMoneyBudget }}</span>
					</div>
					<div class="cell">
						<div>预付意向金</div>
						<span>￥{{ orderInfo.sendMoneyIntention }}</span>
					</div>
					<div class="cell">
						<div>联系方式</div>
						<span class="blue" v-if="!getPhone" @click="getPhone = true">点击获取</span><span
							v-else>{{ orderInfo.sendPhone }}</span>
					</div>
				</div>
				<!-- 等待中 -->
				<div class="accepting">
					<div class="cell">
						<div>中标服务方</div>
						{{ orderInfo.winUserName }}
					</div>
					<div class="cell">
						<div>我接受</div>
						{{ orderInfo.winMsgOther }}
					</div>
					<div class="cell">
						<div>联系方式</div>
						{{ orderInfo.winUserPhone }}
					</div>
					<div class="cell">
						<div>合同价格</div>
						<span>{{ orderInfo.winNeedMoney || "待服务方填写" }}</span>
					</div>
					<div class="cell">
						<div>首款比例</div>
						<span>{{
              orderInfo.winFirstProportion
                ? orderInfo.winFirstProportion + "%"
                : "待服务方填写"
            }}</span>
					</div>
				</div>
				<div v-if="repreCur == 0">
					<el-form style="margin-top: 40px" label-position="left" label-width="80px" :model="form"  :rules="rules"
						@submit.native.prevent>
						<el-form-item label="申诉环节" prop="state">
							<div class="code">
								<el-select v-model="form.state" placeholder="服务环节">
									<el-option v-for="item in linkList" :key="item.id" :label="item.title"
										:value="item.id"></el-option>
								</el-select>
							</div>
						</el-form-item>
						<el-form-item label="申诉原因" prop="msg">
							<!-- <div class="code"> -->
							<el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea"
								placeholder="50字以内" v-model="form.msg"></el-input>
							<!-- </div> -->
						</el-form-item>
					</el-form>
					<!-- 底部按钮 -->
					<div>
						<div class="foot-btn">
							<el-button class="orders" @click="appeal">确定转入申诉</el-button>
						</div>
						<div class="hui">
							申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
						</div>
					</div>
				</div>
				<div v-else>
					<div class="orderInformation" style="border: none">
						<div class="cell">
							<div>申诉人</div>
							<span>{{ orderInfo.errorUserName }}</span>
						</div>
						<div class="cell">
							<div>申诉环节</div>
							<span>{{ errorState[active] }}</span>
						</div>
						<div class="cell">
							<div>申诉原因</div>
							<span>{{
                orderInfo.errorMsg == "" ? this.form.msg : orderInfo.errorMsg
              }}</span>
						</div>
					</div>
					<!-- 底部按钮 -->
					<div>
						<div class="foot-btn">
							<el-button class="orders" disabled v-if="!showAppeal">申诉中</el-button>
							<el-button class="orders" v-if="showAppeal" @click="withdraw">撤回申诉</el-button>
							<div class="give-up">平台审核员核实中......</div>
						</div>
						<div class="hui">
							申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Code from "../../components/code.vue";
	import QRCode from "qrcodejs2";
	export default {
		components: {
			Code
		},
		props: {
			dialogVisible: Boolean,
			orderInfo: Object,
		},

		data() {
			return {
				showAppeal: false,
				className: "",
				num: 1,
				active: 0, //当前进度下标
				getPhone: false, //获取手机号
				checked: false, //勾选条款
				obtain: 0, //获取信息 0 为输入信息 1 支付
				payState: 0, //0 支付   1等待
				winState: 0,
				// 发布
				form1: {
					classifyId: "", //分类id
					name: "", //需求名称
					sendMoneyBudget: "", //预算
					sendMsgBase: "", //需求描述
					sendProvince: "", //省
					sendCity: "", //市
					sendIndustry: "", //行业
					smsCode: "", //短信验证码
					sendMsgOther: "", //其他备注
					className: "",
				},
				rules: {
					classifyId : [{
						required: true,
						message: '请选择需求类别',
					}],
					name : [{
						required: true,
						message: '少于20个字',
					}],
					sendMoneyBudget : [{
						required: true,
						message: '预算金额',
					}],
					accountPhone : [{
						required: true,
						message: '联系电话',
					}],
					sendMsgBase : [{
						required: true,
						message: '需求描述',
					}],
					sendProvince : [{
						required: true,
						message: '地区',
					}],
					state : [{
						required: true,
						message: '服务环节',
					}],
					sendIndustry : [{
						required: true,
						message: '行业',
					}],
					msg : [{
						required: true,
						message: '50字以内',
					}]
				},
				industryList: [{
						id: "A",
						title: "农、林、牧、渔业"
					},
					{
						id: "B",
						title: "采矿业,"
					},
					{
						id: "C",
						title: "制造业"
					},
					{
						id: "D",
						title: "电力、热力、燃气及水生产和供应业"
					},
					{
						id: "E",
						title: "建筑业"
					},
					{
						id: "F",
						title: "批发和零售业"
					},
					{
						id: "G",
						title: "交通运输、仓储和邮政业"
					},
					{
						id: "H",
						title: "住宿和餐饮业"
					},
					{
						id: "I",
						title: "信息传输、软件和信息技术服务业"
					},
					{
						id: "J",
						title: "金融业"
					},
					{
						id: "K",
						title: "房地产业"
					},
					{
						id: "L",
						title: "租赁和商务服务业"
					},
					{
						id: "M",
						title: "科学研究和技术服务业"
					},
					{
						id: "N",
						title: "水利、环境和公共设施管理业"
					},
					{
						id: "O",
						title: "居民服务、修理和其他服务业"
					},
					{
						id: "P",
						title: "教育"
					},
					{
						id: "Q",
						title: "卫生和社会工作"
					},
					{
						id: "R",
						title: "文化、体育和娱乐业"
					},
					{
						id: "S",
						title: "公共管理、社会保障和社会组织"
					},
					{
						id: "T",
						title: "国际组织"
					},
				], //行业
				smaCode: "",
				form2: {
					//意向金
					orderId: "",
					money: "",
					payType: 0, //支付方式 0余额 1支付宝 2微信
					payPsd: "",
				},
				form3: {
					//支付收款
					orderId: "",
					payType: 0,
					payPsd: "",
				},
				orderId: "", //订单ID
				disabled: false, //是否禁用
				form4: {
					//尾款
					orderId: "",
					payType: 0,
					payPsd: "",
				},
				form: {
					//申诉
					state: "",
					msg: "",
					orderId: "",
				},
				represent: false, // 申述
				repreCur: 0,
				errorState: {
					2: "接单中标",
					3: "等待对方中标确认",
					4: "服务方工作",
					5: "验收成果",
					6: "支付尾款",
				},
				linkList: [], //申诉转态选择
				cellRadio: 0,
				radio: 0, //zhifu
				activeInput: 0,
				captchas: [
					// 密码
					{
						num: ""
					},
					{
						num: ""
					},
					{
						num: ""
					},
					{
						num: ""
					},
					{
						num: ""
					},
					{
						num: ""
					},
				],
				// 倒计时
				countDownShow: false,
				count: 60,
				countDown: "",
				isdisabledFn: false,
				checkCode: "", //图形码
				qrCodeShow: false, //二维码显示
				info: {},
				classList: [],
				proList: [], //省
				cityList: [], //市
				bidList: [], //已投标用户列表
				orderInfo: {},
				token: "",
				code: "",
				payId: "",
				codePayState: 0,
			};
		},
		watch: {
			// 监听省获取市
			"form1.sendProvince"(val) {
				if (val != "") {
					this.getCity();
				}
			},
			active: {
				handler(val) {
					if (val == 2) {
						this.linkList = [{
								id: 3,
								title: "接单中标"
							},
							// { id: 7, title: '服务方工作' }
						];
					} else if (val == 3) {
						this.linkList = [{
								id: 3,
								title: "接单中标"
							},
							{
								id: 4,
								title: "等待对方中标确认"
							},
						];
					} else if (val == 4) {
						this.linkList = [{
								id: 3,
								title: "接单中标"
							},
							{
								id: 4,
								title: "等待对方中标确认"
							},
							{
								id: 6,
								title: "服务方工作"
							},
						];
					} else if (val == 5) {
						this.linkList = [{
								id: 3,
								title: "接单中标"
							},
							{
								id: 4,
								title: "等待对方中标确认"
							},
							{
								id: 6,
								title: "服务方工作"
							},
							{
								id: 7,
								title: "验收成果"
							},
						];
					} else if (val == 6) {
						this.linkList = [{
								id: 3,
								title: "接单中标"
							},
							{
								id: 4,
								title: "等待对方中标确认"
							},
							{
								id: 6,
								title: "服务方工作"
							},
							{
								id: 7,
								title: "验收成果"
							},
							{
								id: 8,
								title: "支付尾款"
							},
						];
					}
				},
				deep: true,
				immediate: true,
			},
		},
		mounted() {
			this.token = sessionStorage.getItem("token");
			// 判断是否有orderInfo 进行state的进度展示
			if (this.orderInfo) {
				this.orderInfo.orderId = this.orderInfo.id;
				if (this.orderInfo.errorState != 0) {
					this.represent = true;
					this.repreCur = 1;
				}
				this.form2.orderId = this.orderInfo.id;
				
				this.orderId = this.orderInfo.id;
				if (this.orderInfo.state == 1) {
					this.obtain = 1;
					this.active = 1;
				} else if (this.orderInfo.state == 3) {
					this.active = 2;
					this.getUser();
				} else if (this.orderInfo.state == 5) {
					this.active = 3;
					this.payState = 1;
				} else if (this.orderInfo.state == 4) {
					this.active = 3;
					this.payState = 1;
				} else if (this.orderInfo.state == 6) {
					// 获取价值百分比
					this.$set(this.orderInfo, "moneyAll", "");
					if (
						(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion <=
						this.orderInfo.sendMoneyIntention
					) {
						this.orderInfo.moneyAll = 0;
					} else {
						this.orderInfo.moneyAll =
							(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion -
							this.orderInfo.sendMoneyIntention;
					}
					this.active = 3;
				} else if (this.orderInfo.state == 7) {
					this.active = 4;
				} else if (this.orderInfo.state == 8) {
					// 获取价值百分比
					if (
						(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion <=
						this.orderInfo.sendMoneyIntention
					) {
						this.orderInfo.moneyAll = 0;
					} else {
						this.orderInfo.moneyAll =
							(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion -
							this.orderInfo.sendMoneyIntention;
					}
					this.active = 5;
				} else if (this.orderInfo.state == 9) {
					// 获取价值百分比
					this.$set(this.orderInfo, "moneyAll", "");
					if (
						(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion <=
						this.orderInfo.sendMoneyIntention
					) {
						this.orderInfo.moneyAll = 0;
					} else {
						this.orderInfo.moneyAll =
							(this.orderInfo.winNeedMoney / 100) * this.orderInfo.winFirstProportion -
							this.orderInfo.sendMoneyIntention;
					}
					this.active = 6;
				} else if (this.orderInfo.state == 10) {
					this.active = 7;
				} else if (this.orderInfo.state == 11) {
					this.active = 8;
				}
			}
			this.getInfo();
			this.getPro();
			this.getClass();
			this.BooleanRed();
			console.log(this.active);

		},
		created() {},
		methods: {
			//消除红点
			BooleanRed() {
				if (this.orderInfo) {
					if (
						this.orderInfo.redUid != "" &&
						this.orderInfo.redUid != null &&
						this.orderInfo.redUid != undefined
					) {
						this.$util
							.post("/demand/readeOrder", {
								orderId: this.orderInfo.id,
							})
							.then((res) => {
								console.log(res);
							});
					}
				}
			},
			// 获取省
			getPro() {
				this.$util.post("/address/provinces").then((res) => {
					this.proList = res.data;
				});
			},
			getCity() {
				this.form.city = "";
				this.$util
					.post("/address/city", {
						fid: this.form1.sendProvince,
					})
					.then((res) => {
						this.cityList = res.data;
					});
			},
			handleClose() {
				if (this.active == 1) {
					this.$confirm("关闭将不保存此单据!!")
						.then((_) => {
							this.$emit("close");
						})
						.catch((_) => {});
				} else {
					this.$emit("close");
				}
			},
			getInfo() {
				this.$util.post("/user/info").then((res) => {
					this.info = res.data;
					this.form1.sendProvince = this.info.province;
					this.form1.sendCity = this.info.city;
					this.form1.sendIndustry = this.info.industry;
					if (this.info.id == this.orderInfo.errorUid) {
						this.showAppeal = true;
					} else {
						this.showAppeal = false;
					}
				});
			},
			getClass() {
				this.$util.post("/shop/allClassify").then((res) => {
					this.classList = res.data;

					console.log(this.orderInfo);

					if (this.orderInfo != undefined) {
						for (var i in this.classList) {
							if (this.orderInfo.classifyId == this.classList[i].id) {
								this.className = this.classList[i].name;
							}
						}
					}

					// if (this.orderInfo.classifyId) {
					//   res.data.forEach((item) => {
					//     // if (this.orderInfo.classifyId == item.id) {
					//     //   this.orderInfo.classifyId = item.name;
					//     // }
					//   });
					// }
				});
			},
			getCode() {
				// const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
				this.$util
					.post("sys-sms/sms-send", {
						phone: this.info.accountPhone,
						action: "Other",
					})
					.then((res) => {
						if (res.code == 200) {
							this.countDownShow = true;
							this.settime();
						}
					});
			},
			settime() {
				if (this.count == 0) {
					this.count = 60;
					this.countDownShow = false;
					return false;
				} else {
					this.countDownShow = true;
					this.isdisabledFn = true;
					this.countDown = "重新发送" + "(" + this.count + ")";
					this.count--;
				}
				setTimeout(() => {
					this.settime();
				}, 1000);
			},
			changeCode(value) {
				this.checkCode = value;
			},
			// 验证码验证
			compare(num) {
				if (this.form1.classifyId == "") {
					return this.$message.error("请选择类别");
				} else if (this.form1.name == "") {
					return this.$message.error("请输入需求名称");
				} else if (this.form1.name.length > 20) {
					return this.$message.error("需求名称小于20个字");
				} else if (this.form1.sendMoneyBudget == "" || this.form1.sendMoneyBudget == "0") {
					return this.$message.error("请输入正确的预算金额");
				} else if (this.form1.sendMoneyBudget == "" || this.form1.sendMoneyBudget == "0") {
					return this.$message.error("请输入正确的预算金额");
				}

				if (this.smaCode.toUpperCase() === this.checkCode) {
					// if (this.active++ > 2) this.active = 0;
					// this.obtain = 1
					for (var i in this.classList) {
						if (this.form1.classifyId == this.classList[i].id) {
							this.className = this.classList[i].name;
						}
					}
					this.active++;
				} else {
					return this.$message.error("图形验证码不正确,请重新输入");
				}
			},
			changeImg(num) {
				this.$refs["ref_validateCode" + num].draw();
			},
			// 输入密码
			adjust(index) {
				let dom = document.getElementById("captcha" + this.activeInput);
				if (index !== this.activeInput && dom) {
					dom.focus();
				}
			},
			// 控制前后方向
			inputDirection(index) {
				let val = this.captchas[index].num;
				// 回退键处理
				if (event.keyCode == 8 && val == "") {
					// 重新校准
					let dom = document.getElementById("captcha" + (index - 1));
					this.activeInput = index - 1;
					if (dom) dom.focus();
				}
				if (event.keyCode != 8 && val != "") {
					let dom = document.getElementById("captcha" + (index + 1));
					this.activeInput = index + 1;
					if (dom) dom.focus();
				}
			},
			// 输入框相互联动
			inputFinash(index) {
				let val = this.captchas[index].num;
				this.activeInput = val ? index + 1 : index - 1;
				let dom = document.getElementById("captcha" + this.activeInput);
				if (dom) dom.focus();
				if (index == this.captchas.length - 1) {
					let code = this.captchas.map((x) => x.num).join("");
					if (code.length == 6) {
						this.form2.payPsd = code;
					}
				}
			},
			// 获取已投标用户列表
			getUser() {
				this.$util
					.post("/demand/bidList", {
						orderId: this.orderId,
					})
					.then((res) => {
						res.data.forEach((item) => {
							item.status = false;
							item.openPhone = false;
						});
						this.bidList = res.data;
					});
			},

			// 输入密码2
			adjusto(index) {
				let dom = document.getElementById("captch" + this.activeInput);
				if (index !== this.activeInput && dom) {
					dom.focus();
				}
			},
			// 控制前后方向
			inputDirectiono(index) {
				let val = this.captchas[index].num;
				// 回退键处理
				if (event.keyCode == 8 && val == "") {
					// 重新校准
					let dom = document.getElementById("captch" + (index - 1));
					this.activeInput = index - 1;
					if (dom) dom.focus();
				}
				if (event.keyCode != 8 && val != "") {
					let dom = document.getElementById("captch" + (index + 1));
					this.activeInput = index + 1;
					if (dom) dom.focus();
				}
			},
			// 输入框相互联动
			inputFinasho(index) {
				let val = this.captchas[index].num;
				this.activeInput = val ? index + 1 : index - 1;
				let dom = document.getElementById("captch" + this.activeInput);
				if (dom) dom.focus();
				if (index == this.captchas.length - 1) {
					let code = this.captchas.map((x) => x.num).join("");
					if (code.length == 6) {
						this.form3.payPsd = code;
					}
				}
			},
			// 输入密码3
			adjustb(index) {
				let dom = document.getElementById("captchb" + this.activeInput);
				if (index !== this.activeInput && dom) {
					dom.focus();
				}
			},
			// 控制前后方向
			inputDirectionb(index) {
				let val = this.captchas[index].num;
				// 回退键处理
				if (event.keyCode == 8 && val == "") {
					// 重新校准
					let dom = document.getElementById("captchb" + (index - 1));
					this.activeInput = index - 1;
					if (dom) dom.focus();
				}
				if (event.keyCode != 8 && val != "") {
					let dom = document.getElementById("captchb" + (index + 1));
					this.activeInput = index + 1;
					if (dom) dom.focus();
				}
			},
			// 输入框相互联动
			inputFinashb(index) {
				let val = this.captchas[index].num;
				this.activeInput = val ? index + 1 : index - 1;
				let dom = document.getElementById("captch" + this.activeInput);
				if (dom) dom.focus();
				if (index == this.captchas.length - 1) {
					let code = this.captchas.map((x) => x.num).join("");
					if (code.length == 6) {
						this.form4.payPsd = code;
					}
				}
			},
			// 付款
			pay() {
				if (this.active++ > 6) this.active = 0;
			},
			// 发布需求
			release() {
				if (this.form1.sendMsgBase == "") {
					return this.$message.error("请输入需求描述");
				} else if (this.form1.sendMsgBase.length >= 500) {
					return this.$message.error("请输入500字以内的需求描述");
				} else if (this.form1.sendProvince == "" || this.form1.sendCity == "") {
					return this.$message.error("请选择地区");
				} else if (this.form1.sendMsgOther.length > 120) {
					return this.$message.error("请输入120字以内的其他要求");
				} else if (this.form1.smsCode == "") {
					return this.$message.error("请输入验证码");
				} else {
					this.$util.post("/demand/createOrder", this.form1).then((res) => {
						if (res.code == 200) {
							console.log(res);
							this.obtain = 1;
							this.form2.orderId = res.data.id;
							this.orderId = res.data.id;
							this.orderInfo = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 获取支付状态
			payGetState() {
				this.$util
					.post("/center/myOrder/getRecordState", {
						id: this.payId,
					})
					.then((res) => {
						console.log(res);
						this.codePayState = res.data.state;
					});
			},
			creatQrCode() {
				this.qrCodeShow = true;
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: this.createdCode,
					width: 257,
					height: 257,
					colorDark: "#000000",
					colorLight: "#ffffff",
					correctLevel: QRCode.CorrectLevel.H,
				});
			},
			// 意向金
			intention(num) {
				if (num == 0) {
					this.form2.money = "0";
					// this.form2.payType = 1;
					this.$util.post("/demand/payMoneyIntention", this.form2).then((res) => {
						if (res.code == 200) {
							this.active++;
							this.getUser();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					if (this.form2.money == "") {
						return this.$message.error("请输入预付金额");
					}else{
						this.$util.post("/demand/payMoneyIntention", this.form2).then((res) => {
							if (res.code == 200) {
								console.log(res);
								if (this.form2.payType == 1 || this.form2.payType == 2) {
									this.createdCode = res.data.code;
									this.creatQrCode();
									this.payId = res.data.id;
									let timmer = setInterval(() => {
										if (this.codePayState == "0") {
											this.payGetState();
										} else {
											clearInterval(timmer);
											if (this.codePayState == 1) {
												this.$emit("close");
												return this.$message.error("支付超时");
											} else if (this.codePayState == 2) {
												this.orderInfo.sendMoneyIntention = this.form2.money;
												this.active++;
												this.getUser();
												return this.$message.success("支付成功");
											}
										}
									}, 2000);
								} else {
									this.orderInfo.sendMoneyIntention = this.form2.money;
									this.active++;
									this.getUser();
								}
							}
							//  else if (res.code == 400) {
							// 	this.$message.error("请输入预付金额");
							// } 
							else {
								this.$message.error(res.message);
							}
						});
					}
				}
			},
			// 淘汰
			disuse() {
				let some = this.bidList.filter((item) => {
					return item.status;
				});
				if (some.length == 0) {
					return this.$message.error("最少勾选一个");
				}
				let list = [];
				some.forEach((item) => {
					list.push(item.id);
				});
				this.$util
					.post("/demand/chooseWinner", {
						orderId: this.orderId,
						bidId: list.join(","),
						state: 4,
					})
					.then((res) => {
						if (res.code == 200) {
							this.getUser();
						} else {
							this.$message.error(res.message);
						}
					});
			},
			// 中标
			winBid() {
				let some = this.bidList.filter((item) => {
					return item.status;
				});
				if (some.length == 0) {
					return this.$message.error("最少勾选一个");
				}
				this.$util
					.post("/demand/chooseWinner", {
						orderId: this.orderId,
						bidId: some[0].id,
						state: 3,
					})
					.then((res) => {
						if (res.code == 200) {
							this.active++;
							this.payState = 1;
						} else {
							this.$message.error(res.message);
						}
					});
			},
			// 支付首款
			firstModel() {
				this.form3.orderId = this.orderInfo.id;
				this.$util.post("/demand/payFirstParagraph", this.form3).then((res) => {
					if (res.code == 200) {
						if (this.form3.payType == 1 || this.form3.payType == 2) {
							this.createdCode = res.data.code;
							this.creatQrCode();
							this.payId = res.data.id;
							let timmer = setInterval(() => {
								if (this.codePayState == "0") {
									this.payGetState();
								} else {
									clearInterval(timmer);
									if (this.codePayState == 1) {
										this.$emit("close");
										return this.$message.error("支付超时");
									} else if (this.codePayState == 2) {
										this.active++;
										this.getUser();
										return this.$message.success("支付成功");
									}
								}
							}, 2000);
						} else {
							this.active++;
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 验收
			acceptance() {
				// console.log(this.orderInfo.sendMoneyLess);
				this.$util
					.post("/demand/receive", {
						orderId: this.orderInfo.id,
					})
					.then((res) => {
						if (res.code == 200) {
							if (this.orderInfo.sendMoneyLess == 0) {
								this.active = 8
							} else {
								this.active++;
							}

						} else {
							this.$message.error(res.message);
						}
					});
			},
			// 下载文件
			dowmFild() {
				// this.$util.get('/file/download', {
				//     fileUrl: this.orderInfo.winOverFile,
				//     token: sessionStorage.getItem('token')
				// }).then(res => {
				// })
			},
			// 尾款
			tail() {
				// console.log(this.orderInfo.sendMoneyLess);
				this.form4.orderId = this.orderInfo.id;
				this.$util.post("/demand/payLessMoney", this.form4).then((res) => {
					if (res.code == 200) {
						if (this.form4.payType == 1 || this.form4.payType == 2) {
							this.createdCode = res.data.code;
							this.creatQrCode();
							this.payId = res.data.id;
							let timmer = setInterval(() => {
								if (this.codePayState == "0") {
									this.payGetState();
								} else {
									clearInterval(timmer);
									if (this.codePayState == 1) {
										this.$emit("close");
										return this.$message.error("支付超时");
									} else if (this.codePayState == 2) {
										this.active++;
										this.getUser();
										return this.$message.success("支付成功");
									}
								}
							}, 2000);
						} else {
							this.active++;
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 申诉打开
			appealOpen() {
				this.represent = true;
			},
			// 申诉
			appeal() {
				this.form.orderId = this.orderInfo.orderId ?
					this.orderInfo.orderId :
					this.orderInfo.id;
				console.log(this.form);

				this.$util.post("/demand/error/send", this.form).then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.repreCur = 1;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 撤回申诉
			withdraw() {
				this.$util
					.post("/demand/error/close", {
						orderId: this.orderInfo.orderId ? this.orderInfo.orderId : this.orderInfo.id,
					})
					.then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.represent = false;
							this.repreCur = 0;
						} else {
							this.$message.error(res.message);
						}
					});
			},
		},
		computed: {
			// 中标不可多选  多选禁用
			btnDelet() {
				let some = this.bidList.filter((item) => {
					return item.status;
				});
				if (some.length > 1) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	// 弹出框

	.now {
		font-size: 18px;
		color: #999999;
		margin-bottom: 30px;
	}

	/deep/.el-step__title.is-wait {
		// background: #e40012;
		color: #666;
	}

	/deep/.el-step__title.is-process {
		color: #e40012;
	}

	/deep/.el-step__icon.is-text {
		color: #666666;
		width: 36px;
		height: 36px;
	}

	/deep/.el-step__head.is-process {
		border-color: #e40012;
	}

	/deep/.el-step__title.is-finish {
		color: #e40012;
	}

	/deep/.el-step__head.is-finish {
		border-color: #e40012;
	}

	.look {
		display: flex;
		align-items: center;
		color: #222;
		font-size: 16px;
		margin: 40px 0;

		div {
			color: #999999;
			margin-right: 20px;
		}
	}

	.blue {
		color: #2ea7e0;
		font-size: 12px;
		// margin-top: 10px ;
		margin-bottom: 30px;
	}

	.checked {
		margin-top: 30px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #666666;

		span {
			color: #2ea7e0;
		}
	}

	.state {
		padding: 20px 0 10px;
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #333333;

		span {
			color: #e40012;
		}

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}

	// /deep/.el-input__inner {
	//     width: 490px;
	// }
	.code {
		height: 48px;
		// border: 1px solid #dcdfe6;
		display: flex;
		align-items: center;

		// width: 500px;
		input {
			height: 100%;
			// border: none;
			outline: none;
			padding: 0 15px;
			border-radius: 4px;
			font-size: 16px;
			border: 1px solid #eeeeee;
		}

		button {
			background-color: transparent;
			border: none;
			color: #e40012;
			font-weight: bold;
			font-size: 14px;
		}
	}

	input::-webkit-input-placeholder {
		color: #c0c4cc;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #c0c4cc;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #c0c4cc;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #c0c4cc;
	}

	.verification {
		display: flex;
		margin-top: 10px;
		align-items: center;

		.text {
			font-size: 14px;
			color: #666666;
			cursor: pointer;
			margin-left: 10px;

			span {
				color: #e40012;
			}
		}
	}

	.privacy {
		font-size: 16px;
		color: #00a71c;
		margin: 30px 0 40px;
	}

	.hint {
		font-size: 16px;
		color: #999999;
		margin: 30px 0;
	}

	.ysmoney {
		padding: 15px 60px;
		border: 1px solid #eeeeee;
		color: #e40012;
		font-size: 16px;
		// text-align: center;
		text-align: center;
		display: inline-block;
		border-radius: 4px;
	}

	.payment {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 400px;
		padding: 15px 30px;
		border: 1px solid #eeeeee;
		margin-bottom: 20px;
		color: #999;
	}

	.raido {
		display: flex;
		align-items: center;
	}

	.raido-info {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		margin-left: 10px;
		align-items: center;
		color: #222;

		div {
			display: flex;
			align-items: center;
		}

		img {
			margin-right: 10px;
			// margin-top: ;
		}

		span {
			color: #999999;
			font-size: 14px;
			margin-left: 10px;
		}
	}

	.deposit {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #222;

		span {
			color: #e40012;
		}

		.price {
			padding: 15px 48px;
			border: 1px solid #eeeeee;
			margin-right: 20px;
			text-align: center;

			div {
				color: #999999;
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}

	.paypass {
		display: flex;
		align-items: center;

		input {
			width: 64px;
			height: 64px;
			border: 1px solid #d1d1d1;
			outline: none;
			text-align: center;
			line-height: 64px;
			font-size: 60px;
			margin-right: 20px;
		}
	}

	::-ms-clear {
		display: none;
	}

	::-ms-reveal {
		display: none;
	}

	.orderInformation {
		padding: 20px;
		border: 1px solid #eeeeee;
		margin: 30px 0 30px;

		.cell {
			display: flex;
			font-size: 16px;
			color: #999999;
			margin-bottom: 10px;

			div {
				width: 100px;
			}

			span {
				color: #222222;
				margin-left: 65px;
			}

			.blue {
				font-size: 16px;
				color: #2ea7e0;
			}
		}
	}

	.accept {
		display: flex;
		font-size: 16px;
		color: #999999;

		span {
			margin-top: 15px;
		}

		div {
			flex: 1;
			height: 160px;
			border: 1px solid #eeeeee;
			padding: 15px 10px;
			margin-left: 20px;
		}
	}

	.accepting {
		padding: 20px;
		border: 1px solid #eeeeee;

		.cell {
			font-size: 16px;
			color: #222222;
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			div {
				width: 100px;
				color: #999999;
				margin-right: 50px;
			}

			span {
				color: #e40012;
			}
		}
	}

	.wait {
		font-size: 18px;
		color: #999999;
		text-align: center;
		margin: 100px 0;
	}

	.wait-btn {
		padding: 13px 29px;
		background: #d1d1d1 !important;
	}

	.give-up {
		margin-left: 80px;
		font-size: 18px;
		color: #e40012;
	}

	.no-win {
		text-align: center;
		font-size: 18px;
		color: #999999;
		margin-top: 150px;
		margin-bottom: 70px;

		span {
			color: #e40012;
		}

		img {
			width: 56px;
			height: 56px;
			display: block;
			margin: 0 auto 20px;
		}
	}

	.time-out {
		display: flex;
		align-items: center;
		margin: 100px 0;
		color: #ffcc00;
		font-size: 18px;
		justify-content: center;

		img {
			width: 38px;
			height: 32px;
			margin-right: 20px;
		}
	}

	.foot-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 90px;
	}

	.orders {
		// margin-top: 90px;
		// width: 247px;
		// height: 50px;
		padding: 13px 100px;
		background: #e40012;
		color: #fff;
		font-size: 18px;
		border-radius: 4px;
		// margin: 90px 0 0;
	}

	.disuse {
		padding: 13px 100px;
		color: #e40012;
		font-size: 18px;
		border: 1px solid #e40012;
		border-radius: 4px;
		margin-left: 100px;
	}

	.upload {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 30px 0;
		color: #999999;

		.lable {
			margin-right: 20px;
		}
	}

	/deep/.el-upload--picture-card {
		width: 90px;
		height: 90px;
		line-height: 100px;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 90px;
		height: 90px;
	}

	.l-hint {
		div {
			color: #2ea7e0;
			margin-bottom: 10px;
		}

		font-size: 14px;
		color: #222222;
	}

	.hui {
		text-align: center;
		font-size: 14px;
		color: #999999;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.red {
		color: #e40012 !important;
	}

	.moengt {
		display: flex;
		align-items: center;
		border: 1px solid #eee;
		color: #999999;
		padding: 15px 10px;
		width: 200px;

		input {
			flex: 1;
			padding-left: 10px;
			font-size: 16px;
			border: none;
			outline: none;
		}
	}

	.backJd {
		margin: 20px 0;
		color: #222;
		font-size: 14px;
		display: flex;
		align-items: center;
	}

	.qr-code {
		width: 257px;
		height: 257px;
		margin: 40px auto;
		background: red;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.menus {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		color: #222;

		.li {
			width: 150px;
			text-align: center;
		}
	}

	.menus-cell {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		color: #666;
		padding: 30px 0;
		border-bottom: 1px solid #eeeeee;

		.li {
			width: 150px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

			div {
				margin-left: 10px;
				font-size: 16px;
			}
		}
	}

	.foot-content {
		margin: 20px 0;

		.cell {
			color: #222222;
			font-size: 15px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			div {
				width: 100px;
				color: #999999;
				margin-right: 50px;
			}
		}
	}

	.wenj {
		display: flex;
		align-items: center;
		margin: 20px 0 0;
	}
</style>
